<template lang="pug">

v-container(fluid).doradociard
  
  v-row.justify-center.align-center
    v-col(cols=12 md=7 style="margin-bottom: 3em; margin-top: 3em")
      v-img(  :src='nosotros.ventana' height='100px' contain ).d-none.d-md-block
      v-img(  :src='nosotros.ventana' height='60px' contain ).d-md-none  
      h2.text-center.font-weight-bold.mb-5.mt-2( v-html='mediacion_normativa.titulo').d-none.d-md-block
      h3.text-center.font-weight-bold.mb-5.mt-2( v-html='mediacion_normativa.titulo').d-md-none  

      div(v-html='mediacion_normativa.Subtitulo').text-center.align-center.d-none.d-md-block
      p.text-caption(v-html='mediacion_normativa.Subtitulo').text-center.align-center.d-md-none


    v-col( cols=12 md=12).justify-center.align-center.mb-8
      template( v-for='{titulo, vinculo} of mediacion_normativa.normativas' )
        a.link-normativa( :href='vinculo' target='_blank' v-html='titulo' style="color: #003452").text-center.d-none.d-md-block.font-weight-bold

        a.link-normativa( :href='vinculo' target='_blank' v-html='titulo' style="color: #003452").text-center.font-weight-bold.d-md-none
  v-row(justify='center').mb-5
    template( v-for='{imagen, button, vinculo} of mediacion_normativa.formatos' ) 
      v-col( cols=10 md=2)
        
          v-img( :src='imagen' )
          v-card-actions
            v-spacer
            v-btn( :href='vinculo' target='_blank' id="Dp" outlined v-html='button').button.d-md-none
            v-btn( :href='vinculo' target='_blank' v-html='button' ).primary.d-none.d-md-flex
            
            v-spacer

      

</template>
<script>
export default {
  components: {
  }
}
</script>
<style lang="sass">
.link-normativa
  text-decoration: none
.texto
  color:#003452
</style>
<style >
.colorazultexto{
  color: #003452;
}
.button#Dp {
  background-color: transparent;
  color: #d19100;
  border:1px solid #d19100;
}
.button#Dp:hover {
    

  background-color:#003452;
  color: #ffffff;

  border:1px solid #003452;
}
</style>